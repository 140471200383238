module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"us5.datadoghq.com","sampleRate":100,"enabled":true,"rum":{"applicationId":"4e2ee008-f188-492c-b4c6-6b695772d8de","clientToken":"pub433009872f641faefb3c0b2e009452d7"},"logs":{"clientToken":"pub433009872f641faefb3c0b2e009452d7"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
